.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../images/ph-hero.jpeg'); /* Replace with a relevant pharma image URL */
    background-size: cover;
    background-position: center;
    color: #f6a33d;
    text-align: center;
  }
  
  .hero-content {
    /* background: rgba(0, 0, 0, 0.6); Semi-transparent background */
    padding: 30px;
    border-radius: 10px;
  }
  
  .hero-title {
    font-size: 4rem;
    margin: 0;
  }
  
  .hero-subtitle {
    font-size: 2rem;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .hero-button {
    padding: 12px 24px;
    font-size: 1rem;
    color: white;
    background-color: #28a745; /* Green color for pharma */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .hero-button:hover {
    background-color: #218838; /* Darker green on hover */
  }
  