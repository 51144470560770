/* RequestForm.css */

.request-form {
    background-color: #f4f4f9;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
  }
  
  .request-form h2 {
    text-align: center;
    color: #004080;
    margin-bottom: 20px;
  }
  
  .request-form form {
    display: flex;
    flex-direction: column;
  }
  
  .request-form input,
  .request-form textarea {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .request-form input:focus,
  .request-form textarea:focus {
    outline: none;
    border-color: #004080;
    box-shadow: 0 0 5px rgba(0, 64, 128, 0.3);
  }
  
  .request-form textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .request-form button {
    background-color: #004080;
    color: #fff;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
  }
  
  .request-form button:hover {
    background-color: #0059b3;
  }
  
  @media (max-width: 600px) {
    .request-form {
      padding: 20px;
    }
  
    .request-form h2 {
      font-size: 24px;
    }
  
    .request-form input,
    .request-form textarea {
      font-size: 14px;
    }
  
    .request-form button {
      font-size: 16px;
    }
  }