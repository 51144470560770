/* COMMON CSS */

.section-vertical-padding{
  padding-top: 3rem;
  padding-bottom: 3rem;
}


/* For Button */
.btn-new{
    background: #2d3a53;
    white-space: nowrap;
    outline: none;
    border: none;
    min-width: 100px;
    max-width: 200px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    padding: 14px 24px;
    color: #fff;
    font-size: 14px;
}

.btn-new:hover{
  transform: translateY(-2px);
        /*background: #195f83;*/
        background: #000d1a;
        color: #fff;
}