/* Footer.css */

.footer {
    background-color: #004080;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    margin-bottom: 10px;
  }
  
  .footer-links {
    margin: 10px 0;
  }
  
  .footer-links a {
    color: #aad1f2;
    text-decoration: none;
    margin: 0 15px;
    font-size: 16px;
    transition: color 0.3s;
  }
  
  .footer-links a:hover {
    color: #fff;
  }
  
  .footer-socials {
    margin-top: 10px;
  }
  
  .footer-socials a {
    color: #aad1f2;
    text-decoration: none;
    margin: 0 10px;
    font-size: 16px;
    transition: color 0.3s;
  }
  
  .footer-socials a:hover {
    color: #fff;
  }