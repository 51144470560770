.alt-section{
  background-color: #c2d6f1;
}

.card {
    text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .small-card-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .card-content {
    padding: 15px;
  }
  
  .small-card-title {
    font-size: 1.25rem;
    margin: 0 0 10px;
  }
  
  .small-card-description {
    font-size: 1rem;
    color: #555;
  }
  