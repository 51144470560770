/* ContactUs.css */

.contact-us {
    background-color: #f4f4f9;
    padding: 40px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contact-us h2 {
    color: #004080;
    margin-bottom: 20px;
  }
  
  .contact-us p {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .contact-us a {
    color: #004080;
    text-decoration: none;
    font-weight: bold;
  }
  
  .contact-us a:hover {
    text-decoration: underline;
  }




  .map-section {
    /* background-color: #f0f0f0; Light background color */
    text-align: center;
  }
  
  .map-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333; /* Dark text color */
  }
  
  .map-section iframe {
    max-width: 100%; /* Ensure the iframe is responsive */
    border-radius: 10px; /* Optional rounded corners */
  }
  