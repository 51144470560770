.service-section{
    padding: 3rem 1.5rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    display: block;
}
    
.card-item{
    border: 1px solid rgba(82, 86, 94, 0.2);
    background-image: url("../images/service44.jpeg");  
    opacity: 0.8;
    /* position: relative; */
    border-radius: 0;
    margin: 40px;
    overflow: hidden;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    transition: opacity 0.3s, transform 0.3s, background-color 0.3s;
    transition: 0.3s ease-in-out;
    position: relative;  
}
  
/* .card-item:hover{
    background: linear-gradient(90deg, #ff87a8 0%, #0019ff 125%);
    color: #FFFFFF;
} */

.heading-text{
    font-size: 20px;
}

.heading-text1{
    font-size: 25px;
    font-weight: bold;
}

.service-title{
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    padding: 5px;
}

.service-points{
    font-size: 15px;
    color: #000;
}