/* Services.css */

.services {
    /* background-color: #f4f4f9; */
    background-image:url(../images/Homepage3.avif) ;
    padding: 50px;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    max-width: 900px;
    margin: 40px auto;
    text-align: left;
  }
  
  .services h2 {
    color: #004080;
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .services p {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .services ul {
    list-style-type: none;
    padding: 0;
  }
  
  .services ul li {
    font-size: 16px;
    color: #333;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .services ul li:last-child {
    border-bottom: none;
  }