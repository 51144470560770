.careers-form-container {
  background-image:url(../images/Homepage3.avif) ;

    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  p {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .careers-form .form-group {
    margin-bottom: 15px;
  }
  
  .careers-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .careers-form input,
  .careers-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .careers-form textarea {
    resize: none;
    height: 100px;
  }
  
  .error {
    color: red;
    font-size: 0.875rem;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  