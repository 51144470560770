.about {
  display: flex;
  align-items: center;
  padding: 50px;
  background-color: #c2d6f1; /* Light background color */
}

.about-content {
  flex: 1;
  padding-right: 20px; /* Space between text and image */
}

.about-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #333; /* Dark text color for readability */
  text-align: left;
  
}

.about-image {
  flex: 1;
}

.about-image img {
  max-width: 100%;
  border-radius: 10px; /* Optional rounded corners */
}


.list-close{
  padding: 30px;
}